import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

export const Brief: React.FC = observer(({ children }) => {
    return <StyledCard>{children}</StyledCard>;
});

const StyledCard = styled(Card)`
    border: none;
`;
