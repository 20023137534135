import { Input as AntInput } from 'antd';
import styled from 'styled-components';

type InputProps = {
    // eslint-disable-next-line react/require-default-props
    value?: string;
    // eslint-disable-next-line react/require-default-props
    onChange?: (_value: string) => void;
    // eslint-disable-next-line react/require-default-props
    readOnly?: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    maxLength?: number;
    // eslint-disable-next-line react/require-default-props
    max?: number;
    // eslint-disable-next-line react/require-default-props
    onlyNumbers?: boolean;
    // eslint-disable-next-line react/require-default-props
    addonAfter?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    placeholder?: any;
    // eslint-disable-next-line react/require-default-props
    prefix?: any;
};

const ONLY_NUMBERS_PATTERN = /^\d*$/;

export const Input = ({
    onChange,
    value,
    onlyNumbers,
    maxLength,
    max,
    prefix,
    ...props
}: InputProps) => {
    const validateNumbers = (v: string) => {
        if (ONLY_NUMBERS_PATTERN.test(v)) {
            if (max) {
                max >= Number(v) ? onChange?.(v) : onChange?.(value || '');
            } else {
                onChange?.(v);
            }
        } else {
            onChange?.(value || '');
        }
    };

    return (
        <StyledInput
            {...props}
            value={value}
            maxLength={maxLength}
            prefix={prefix}
            onChange={(e) => {
                const v = e.target.value;
                if (onlyNumbers) {
                    validateNumbers(v);
                } else {
                    onChange?.(v);
                }
            }}
        />
    );
};

const StyledInput = styled(AntInput)`
    height: 40px;
    border-radius: 6px;
    border-color: #000000;
`;
