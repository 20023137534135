/* eslint-disable no-underscore-dangle */
import Keycloak from 'keycloak-js';
import { action, computed, observable } from 'mobx';
import { Authentication, UiSettings } from 'src/generated-api-client';
import i18next, { initInternationalization } from 'src/i18n';
import { uiSettingsApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export const UPDATE_TOKEN_MIN_VALIDITY = 30;
export const SUPPORTED_LOCALES = ['en'];

export class SettingsStoreClass {
    private settingsLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(uiSettingsApi.getSettings()),
    );

    // private backendInfoLoader = new AsyncOperationWithStatus(
    //     () =>
    //         RequestHelper.unwrapFromAxiosPromise(
    //             actuatorApi.info(),
    //         ) as Promise<BackednInfo>,
    // );

    @observable currentLocale?: string;

    @action async load() {
        await this.settingsLoader.call();
        // await this.backendInfoLoader.call();
    }

    @computed get settings(): UiSettings | undefined {
        return this.settingsLoader.data;
    }

    @computed get keycloakSettings() {
        return this.settings?.authentication as Required<Authentication>;
    }

    @computed get hasSettings() {
        return this.settingsLoader.hasData;
    }

    @action setupKeycloak() {
        this._keycloak = new Keycloak(this.keycloakSettings);
        this._keycloak.onTokenExpired = () => {
            this.updateTokenIfNeed();
        };
    }

    @action async updateTokenIfNeed() {
        await this.keycloak?.updateToken(UPDATE_TOKEN_MIN_VALIDITY);
    }

    @observable _keycloak?: Keycloak.KeycloakInstance;

    @computed get keycloak() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this._keycloak!;
    }

    @action async setupInternationalization() {
        await initInternationalization(this.settings as any);
    }

    @computed get supportedLocales() {
        return SUPPORTED_LOCALES;
    }

    @computed get hasFewLocales() {
        return this.supportedLocales.length > 1;
    }

    @action async setLocale(locale: string) {
        await i18next.changeLanguage(locale);
    }

    // @computed get backEndServerVersion() {
    //     return this.backendInfoLoader.data?.build?.version;
    // }
}

export const SettingsStore = new SettingsStoreClass();
