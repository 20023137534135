import { InboxOutlined } from '@ant-design/icons';
import { Form, FormItemProps, Upload, UploadFile, UploadProps } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import styled from 'styled-components';

const { Dragger } = Upload;

const { Item } = Form;

export type UploadInputProps = UploadProps & {
    // eslint-disable-next-line react/require-default-props,@typescript-eslint/no-unused-vars
    onChange?: (fileList: UploadFile[]) => void;
    // eslint-disable-next-line react/require-default-props,@typescript-eslint/no-unused-vars
    triggerIcon?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props,@typescript-eslint/no-unused-vars
    triggerLabel?: React.ReactNode;
};

const UploadInput: React.FC<UploadInputProps> = ({
    maxCount,
    multiple,
    onChange,
    children,
    triggerIcon,
    triggerLabel,
    ...props
}) => {
    const count = maxCount || 1;

    return (
        <Dragger
            maxCount={count}
            multiple={multiple}
            beforeUpload={() => false}
            onChange={(event) => {
                onChange?.([...event.fileList]);
            }}
            {...props}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-hint">Upload a file or drag and drop</p>
        </Dragger>
    );
};

export type FileFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    maxCount?: UploadProps['maxCount'];
    // eslint-disable-next-line react/require-default-props
    multiple?: UploadProps['multiple'];
    // eslint-disable-next-line react/require-default-props
    accept?: UploadProps['accept'];
    // eslint-disable-next-line react/require-default-props
    uploadProps?: Omit<
        UploadProps,
        'maxCount' | 'multiple' | 'onChange' | 'accept'
    >;
};

export const DnDUploader: React.FC<FileFieldProps> = ({
    name,
    label,
    maxCount,
    multiple,
    accept,
    uploadProps,
    children,
    ...props
}) => {
    return (
        <StyledFormItem {...props} name={name} label={label}>
            <UploadInput
                maxCount={maxCount}
                multiple={multiple}
                accept={accept}
                {...uploadProps}
            >
                {children}
            </UploadInput>
        </StyledFormItem>
    );
};

const StyledFormItem = styled(Item)`
    .ant-form-item-control {
        width: 100%;
    }
    .ant-row {
        flex-direction: column;
        align-items: flex-start;
        .ant-upload-drag {
            padding: 50px 40px;
        }
    }
    .ant-upload-drag-icon {
        svg {
            color: #aaaaaa;
        }
    }
`;
