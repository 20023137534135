import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';

export type UserProfile = {
    username: string;
};

export class UserStoreClass {
    @observable private _userProfile?: Keycloak.KeycloakProfile;

    @computed get userProfile() {
        // eslint-disable-next-line no-underscore-dangle
        return this._userProfile;
    }

    @action async loadUserInfo() {
        // eslint-disable-next-line no-underscore-dangle
        this._userProfile = await SettingsStore.keycloak.loadUserProfile();
    }

    @computed get permissions(): string[] {
        return AuthStore.permissions;
    }

    hasPermissions(...permissions: string[]) {
        return (
            _.intersection(
                this.permissions.map((role) => role.toLowerCase()),
                permissions.map((permission) => permission.toLowerCase()),
            ).length > 0
        );
    }

    // @computed get can() {
    //     return generateReadablePermissions(this.roles, true);
    // }

    // @computed get canNot() {
    //     return generateReadablePermissions(this.roles, false);
    // }
}
export const AppUser = new UserStoreClass();
