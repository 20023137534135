/* eslint-disable camelcase */
import { action, computed, observable } from 'mobx';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { TokenHelper } from 'src/utils/TokenHelper';

export class AuthStoreClass {
    @observable authenticated = false;
    @observable actionIdentifier?: string;
    @observable token?: string;

    @action async authenticate() {
        try {
            const authenticated = await SettingsStore.keycloak.init({
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
                pkceMethod: 'S256',
            });
            if (!authenticated) {
                await SettingsStore.keycloak.login();
            }

            this.authenticated = authenticated;
            this.token = SettingsStore.keycloak.token;
            // browserHistory.replace(RoutePaths.index, {});
        } catch (error) {
            this.authenticated = false;
        }
    }

    @action async logout(redirectUrl?: string) {
        await SettingsStore.keycloak?.logout({ redirectUri: redirectUrl });
    }

    @action async changePassword() {
        await SettingsStore.keycloak?.login({ action: 'UPDATE_PASSWORD' });
    }

    @computed get permissions() {
        if (!this.token) {
            return [];
        }

        const parsedToken = TokenHelper.decode(this.token) as {
            authorities?: string[];
        };

        return parsedToken.authorities || [];
    }
}

const getAuthStore = () => {
    return new AuthStoreClass();
};

export const AuthStore = getAuthStore();
