import { Typography } from 'antd';
import styled from 'styled-components';

const { Title } = Typography;

export const BlockTitle = ({ children }: any) => {
    return (
        <TitleContainer>
            <Title level={2}>{children}</Title>
        </TitleContainer>
    );
};

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 25px 0 20px;
    padding: 0 0 5px;
    border-bottom: 1.5px solid #000000;
    box-bottom-shadow: 1px 7px 70px -6px rgba(0, 0, 0, 0.52);
    h2.ant-typography {
        font-size: 1.8rem;
        margin: 0;
    }
`;
