const setFieldErrorsFromRequest = (
    errors: { field: string; message: string }[],
    form: any,
) => {
    return errors.forEach((error) => {
        form.setFields([
            {
                name: error.field,
                errors: [error.message],
            },
        ]);
    });
};

export const FieldValidationHelper = {
    setFieldErrorsFromRequest,
};
