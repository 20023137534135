import { action, computed, observable } from 'mobx';
import { DocumentDTODocumentTypeEnum } from 'src/generated-api-client';

export class UploadFilesStatusStoreClass {
    @observable private uploadFileProgress: Record<
        DocumentDTODocumentTypeEnum | string,
        number
    > = {};

    @action setUploadFileProgress(
        fileType: DocumentDTODocumentTypeEnum | string,
        progress: number,
    ) {
        this.uploadFileProgress[fileType] = progress;
    }

    @action onUploadProgressAction(
        progressEvent: ProgressEvent,
        documentType: DocumentDTODocumentTypeEnum | string,
    ) {
        const currentProgress =
            (progressEvent.loaded / progressEvent.total) * 100;
        this.setUploadFileProgress(documentType, currentProgress);
    }

    @action resetProgress() {
        this.uploadFileProgress = {};
    }

    @computed get getUploadingStatus() {
        return this.uploadFileProgress;
    }

    @computed get getUploadingProgressBarStatus() {
        const arrayOfStatusFileUploading = Object.entries(
            this.uploadFileProgress,
        );

        return (
            arrayOfStatusFileUploading.reduce((init, current) => {
                return init + current[1];
            }, 0) / arrayOfStatusFileUploading.length
        );
    }
}

export const UploadFilesStatusStore = new UploadFilesStatusStoreClass();
