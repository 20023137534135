import { useParams } from 'react-router-dom';

export function useApplicationId() {
    const params = useParams<{ applicationId: string }>();

    return {
        applicationId: params?.applicationId,
        hasapplicationId: Boolean(params?.applicationId),
    };
}
